export default {
    invest: "Invest with us",
    investIntro: "We manage portfolios across a range of different investment styles, we partner with high performance external boutiques to offer high calibre service and financial solutions to investors with different financial objectives, aiming to drive high performance investment to all our stakeholders.",
    
    title: "Why invest with us",
    subTitle1: "Driving high performance",
    subTitle2: "Diversified Portfolio",
    subTitle3: "Accountability",
    subTitle4: "Strong strategic partnerships",

    content1: "With over 40 years expertise spanning a range of investing in domestic and global equities, derivatives, direct residential and commercial real estate, real estate acquisition and development, we all believe in the benefits of active investment management – aiming to outperform the broader market over the long term. Our track record shows exponential growth over our history.",
    content2: "We manage portfolios across a range of different investment styles, asset classes and risk profiles mixed with industry fund, debt income fund, long-short opportunities fund and venture capital fund. Our investment goal is to access higher return with lowest risk.",
    content3: "We are accountable for all our actions, to our stakeholders and to each other. We do not compromise our standards. We take responsibility for our actions and everything we say and do is on the record. We analyze and manage risk, and we make decisions we are proud of.",
    content4: "Beaver Capital is dedicated to offer high-grade service and investment solutions to investors with different financial objectives, We partner with high performance external boutiques to deliver exceptional outcomes for our investors like well-known family offices, top tier real estate. developers, top financial institutions and banks, aiming to outperform the broader market over the long term through the benefits of active investment management.",

    start: "Start Invest",
    faq: "Frequently Asked Questions",

    step1: "Select Fund Products (multiple)",
    step2: 'Select Investor Type',
    step3: "Basic Information",


    q1: "Q: What Is a Fund?",
    a1: "A: A fund is a pool of money that is allocated for a specific purpose. A fund can be established for any purpose whatsoever, whether it is a city government setting aside money to build a new civic center, a college setting aside money to award a scholarship, an insurance company setting aside money to pay its customers’ claims and a financial company allocate money from individuals to reach higher return.",
    q2: "Q: How Funds Work?",
    a2: "A: Individuals, businesses, and governments all use funds to set aside money. Individuals might establish an emergency fund or rainy-day fund to pay for unforeseen expenses or a trust fund to set aside money for a specific person.",
    q3: "Q: What are some common Types of Funds?",
    a3: "A: Trust funds are legal arrangement set up by a grantor who appoints a trustee to administer valuable assets for the benefit of a listed beneficiary for a period of time, after which all or a portion of the funds are released to the beneficiary or beneficiaries. Mutual funds are investment funds managed by professional managers who allocate the funds received from individual investors into stocks, bonds, and/or other assets. Hedge funds are investment vehicles for high-net-worth individuals or institutions designed to increase the return on investors’ pooled funds by incorporating high-risk strategies such as short selling, derivatives, and leverage. Government bond funds are for investors looking to put their money away in low-risk investments through Treasury securities, such as Treasury bonds, or agency-issued debt, such as securities issued by Fannie Mae.",
    q4: "Q: Does Beaver Capital has financial license in Australia?",
    a4: "A: AFSL (Financial License) is 306534.",
    q5: "Q: There are various funds in the market, why should I choose Beaver Capital?",
    a5: "A: Beaver Capital is committed to being a responsible fund management institution, letting investment create value, value promotes investment, and creates a win-win situation.",
    q6: "Q: how do I invest in a Beaver Capital fund?",
    a6: "A: 1. Visit the How to invest page and select the relevant fund. \n 2. Make sure you have read and understood the Information Memorandum.\n 3. Complete and submit the EOI Form, or contact us through email and phone.",
    q7: "Q: Where can I find information about the funds?",
    a7: "A: Offer documents for each of the funds—including Information Memorandum, Additional Information Booklet, Application Form—can be found on the How to invest page.",
    q8: "Q: I don’t know which fund to invest in. Can you help?",
    a8: "A: Our Client Services team can assist with general enquiries, but can’t make any product recommendations or determine whether a fund will meet your personal needs. If you’re unsure, you should speak with your financial adviser.",
    q9: "Q: Do management invest in BC’s products?",
    a9: "A: Many BC employees, including key members of the investment team, have co-invested their personal money in BC’s investment products alongside our clients. There has been a clear alignment of management and employee interests with those of our investors since the firm’s inception.",

    complete: "Complete",
    cText1: "We have sent your account and login information to your email address",
    cText2: "Thank you for choosing Beaver Capital",
};