export default {
    invest: "投资",
    investIntro: "我们管理多种投资策略和多种类别的基金产品。通过与外部优秀的基金经理合作以及自主发行，将不同特点的基金产品带给投资者。我们的目标是实现所有合作伙伴的共赢。",
    
    title: "选择我们",
    subTitle1: "高效率",
    subTitle2: "多元化",
    subTitle3: "可靠性",
    subTitle4: "强合作",

    content1: "我们拥有40多年的专业知识，涉及对国内外股票，衍生品，直接住宅和商业房地产，房地产收购与开发的一系列投资，我们都相信积极投资管理的好处–旨在长期跑赢全球市场的表现。我们的历史交易记录呈现指数性增长。",
    content2: "我们管理的投资组合涵盖多种投资产品包括债务收入基金，多空机会基金和风险投资基金等一系列不同的投资方式，资产类别和风险状况。我们的投资目标是以最低的风险获得更高的回报。",
    content3: "我们对我们的所有行动，利益相关者以及彼此负责。我们不妥协我们的标准。我们对自己的行为负责，我们所说和所做的一切都记录在案。我们分析和管理风险，并做出令我们引以为傲的决策。",
    content4: "海利致力于为有不同财务目标的投资者提供高级服务和投资解决方案。我们与高性能的外部精品店合作，为我们的投资者提供卓越的业绩，例如著名的家族办公室，顶级房地产。开发商，顶级金融机构和银行，其目标是通过积极的投资管理从长远来看要跑赢大盘。",

    start: "开始投资",
    faq: "常见问题",

    step1: "选择基金产品（可多选）",
    step2: '选择投资主体',
    step3: "基本信息",


    q1: "问：什么是基金？",
    a1: "答：基金是为特定目的分配的资金池。建立基金的目的有很多，无论是市政府拨出钱来建立新的市民中心，大学拨出钱来提供奖学金，还是保险公司拨出钱来支付客户的索赔，或是金融公司成立基金来帮客户理财。",
    q2: "问：基金如何运作？",
    a2: "答：个人，企业和政府都可以使用基金。个人可以建立紧急基金或雨天基金来支付不可预见的费用，也可以建立信托基金来为特定的人留出资金。",
    q3: "问：常见的基金类型有哪些？",
    a3: "答：信托基金是由设保人设立的法律安排，设保人指定受托人在一段时间内为受益人的利益管理贵重资产，之后将全部或部分资金释放给受益人或受益人们。\n 共同基金是由专业经理人管理的投资基金，他们将从个人投资者那里获得的资金分配到股票，债券或其他资产中。\n 对冲基金是针对高净值个人或机构的投资工具，旨在通过结合卖空，衍生品和杠杆等高风险策略来增加投资者集合资金的回报。\n 政府债券基金面向希望通过债券例如国债或机构发行的债务例如Fannie Mae发行的证券进行低风险投资的投资者。",
    q4: "问：海利基金在澳大利亚有金融牌照吗？",
    a4: "答：海利的金融牌照是306534",
    q5: "问：市场上有各种各样的基金，为什么要选择海利？",
    a5: "答：海利致力于成为一个负责任的基金管理机构，让投资创造价值，价值促进投资，并创造双赢局面。",
    q6: "问：我如何投资Beaver Capital基金？",
    a6: "答：1.访问“如何投资”页面，然后选择相关基金。\n 2.确保您已阅读并理解《信息备忘录》。\n 3.填写并提交意向书或者通过电话和邮件联系我们。",
    q7: "问：在哪里可以找到有关资金的信息？",
    a7: "答：可以在“如何投资”页面上找到每种基金的详细文件，包括信息备忘录和意向书。",
    q8: "问：我不知道要投资哪种基金。您能帮忙吗？",
    a8: "答：我们的客户服务团队可以协助进行一般咨询，但不能给您任何产品建议或确定基金是否可以满足您的个人需求。如果不确定，请与您的财务顾问联系。",
    q9: "问：管理层是否投资于海利的产品？",
    a9: "答：许多海利员工，包括投资团队的关键成员，已经与我们的客户一起将个人资金共同投资于海利的投资产品中。自公司成立以来，管理层和员工的利益一直与我们投资者的利益保持一致。",

    complete: "注册完成",
    cText1: "我们已将账户及登录信息发送至您的邮箱",
    cText2: "感谢您选择海利基金",
};