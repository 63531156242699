/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  visionOfBeaverTitle: "Beaver Asset Based Lending",
  visionOfBeaverText:
    "Beaver Asset Based Lending Facility (BABL) enables businesses to free up their assets for working capital, capital expenditures or other funding needs. With BABL, your company's wide range of assets including accounts receivable, inventory, PP&E and real property can serve as collateral, freeing up needed capital.",
  whoWeAreTitle: "Beaver Holding Group",
  whoWeAreText:
    "The Beaver Holding Group’s core team has accumulated more than 100 years of experience in financial services and fund management and holds an Australian financial services license.  Our primary focus includes the issuance and operation of mortgage funds, real estate finance, asset allocation, financial management, financial consulting and many other fields.  We combine our investment experiences and competitive advantages across Asia and Australia to provide more high-quality investment opportunities to our clients.",
  fundsTitle: "Beaver Capital Funds",
  fundsText:
    "Beaver Capital offers a specialized investment program focusing on the issuance and operation of mortgage funds, real estate finance, asset allocation, financial management, financial consulting and many other fields to meet the different investment needs of our investors.",
  principleAndStrategyTitle: "BABL",
  principleAndStrategyText:
    "Beaver Asset Based Lending Facility (BABL) enables businesses to free up their assets for working capital, capital expenditures or other funding needs.",
  investWithUsTitle: "Invest With Us",
  investWithUsText:"We manage multiple investment strategies across different categories of asset classes in order to provide fund products with different characteristics to suit the needs of a variety of investors. Our goal is to achieve a win-win situation for all partners.",
  marketTitle: "Latest News and Insights",
  beaverForum: "Beaver Forum",
  quickNews: "Global Weekly Wrap",
};