/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    who:"Beaver Holding Group",
    whoContent1: "Beaver Holding Group is a professional funds investment and funds management company located in Sydney, Australia.",
    whoContent2: "The Beaver Holding Group’s core team has accumulated more than100 years of experience in financial services and fund management and holds an Australian financial services license.  Our primary focus includes the issuance and operation of mortgage funds, real estate finance, asset allocation, financial management, financial consulting and many other fields.  We combine our investment experiences and competitive advantages across Asia and Australia to provide more quality investment opportunities to our clients.",
    whoContent3: "We value client engagement and advice above all else and strive to meet clients’ investment expectations through our professional services.",
    what: "Company Profile",
    whatContent1: "Beaver Capital develops and distributes a wide range of funds, offering high calibre services and investment solutions to institutional clients, financial advisers and direct investors.",
    whatContent2: "Our goal is to assist our broader client base, helping them to achieve their long-term financial targets through a tailored solutions concept. Beaver Capital’s management team are dedicated to integrating environmental as well as social and governance (ESG) practices into our business operations and investment philosophy, ensuring we contribute a positive impact towards our broader Australian community.",

    people: "Our People",
    wwu: "Why Work With Us",
    pwu: "Why Partner With Us",

    storyTitle: "Our Story",
    story1: "Beaver Capital (AFSL: 306534) has been working closely with clients to provide investment solutions to meet their challenges since it was founded in 2006.",
    story2: "The lead members of the team have participated in and led several large-scale real estate development projects in the centre of Sydney, with a transaction volume of more than 1 billion Australian dollars.",
    story3: "Through the combined efforts of our expert team, their extensive professional knowledge and practical experience, we are able to offer the best opportunities in debt-related funds, hedge funds, industrial funds, crypto-currency funds (including fintech) and venture capital funds.",
    story4: "Our focus is to bring the best of Beaver Capital’s investment resources to our clients in the longer term.",

    hansStory: "With over 10 years of real estate acquisition and migration services experience, Hans has participated in the investments of some of the largest landmark property developments in Sydney, Melbourne and Adelaide.\n Hans and his team have played an integral part in assisting the Federal Government’s Significant Investment Visa Scheme, managing a property portfolio in excess of $50 million Australian Dollars. Hans has worked with leading real estate firms and has additionally acquired trophy buildings, allowing his clients to transition smoothly from the Asia-Pacific region to Australia.\n Hans started his career as a commercial lawyer at one of the most reputable law firms in mainland China, headquartered in Beijing. Hans brings over 15 years of funds management and legal experience into Beaver Capital.",
    emmaStory: "Emma brings over 15 years of experience in senior management. Emma started her career as a regional manager for China Mobile, one of the world’s top 500 enterprises. At the peak of her time with China Mobile, Emma managed in excess of 200 staff members across multiple business functions.\n Emma also played a significant role in a portfolio of companies where she was the seed investor. She is passionate about early-stage investment and provides managerial guidance to the senior management team of these companies at an on-going basis.",
    andyStory: "Andy has a dynamic professional career background from over 20 years of senior positions in private banking, funds management, corporate and investment banking, and business entrepreneurship.\n He has qualifications, in-depth knowledge, and experience in financial planning, managed investment trusts, corporate advisory, venture capital, private equity, merger and acquisition; transacting landmark projects in agribusiness, mining, aged care, health science, and real estate investment trust sectors, collective asset value over AUD 3 billion.\n His education and extensive working experience across Australia, China, Asia Pacific nations, give him comforts in dealing with international business and cultural differences. He is well connected to the industries and experts, understanding investment factors, regulations, utilising professional services, association channels.\n Andy brings in his exceptional expertise and value to the group; furthermore, contributes additional time in coaching the new start-ups, and delivers globally renowned WSET wine, spirit, sake education courses. Andy successfully utilising his financial capability with his passion, introduced innovative fine wine trust funds.",

    support: "Full suite of support",
    supContent: "Beaver Capital has built our reputation on success from the effort of our professional teams. We provide our partner with support further than financial backing, through industry data-driven analysis and systems, and a team of talented professionals who offer a full suite of corporate services includes distribution, client services, marketing, compliance, finance, HR, technology and administrative support.",
    win: "Achieve win-win",
    winContent: "Beaver Capital achieve our goals in identifying and partnering with high calibre boutique asset managers, and offering high-grade funds to our clients. It is Beaver Capital’s vision to be recognised and respected for partnering with high performance boutiques, we focuse on growing their business for the benefit of investors.",
    ourPartner: "Our Partner",
    partnerContent1: "Pureheart Capital is a reputed fund management firm which is established in 2003. The company’s firm employs numerous exceptional global macro strategies to analyse world economies.",
    partnerContent2: "In the past decade of global exploration, Pureheart has witnessed the complexity and variability of global political and economic environments. These findings have helped shape and develop company’s unique investment philosophy focusing on global, “boom and bust cycles.” Pureheart is currently managing two hedge funds, namely Pure Heart Value Investment Fund and Pure Heart Natural Selection Fund incepted in 2003 and 2009 respectively.",
    partnerContent3: "The cooperation between Pureheart Capital and Beaver Capital will be beneficial for both sides, helping companies to enter and create new markets, and for new business to develop their products. We work together with a common purpose, which is identifying and partnering with high calibre boutique asset managers, and offering high-grade funds to our clients continuously. For more information, please visit",

    workCulture: "Workplace Culture",
    cultureContent1: "Beaver Capital’s pioneering workplace culture is our competitive edge, we value truthful and transparent communication to ensure the best ideas win out.",
    cultureContent2: "We embrace diversity as we believe the ability to think differently is essential to our growth. We cultivate the hunger for knowledge and the drive to create new opportunities.",
    cultureContent3: "We value inclusion because we believe people show their best performance when they can be their genuine selves.",
    sharedValue: "Shared Values",
    valueContent: "Our values of diversify, mutual respect, open communication and continuous development build our principle of business operation and recruitment. We place an emphasis on flexibility and work/life balance, genuinely enjoy coming to work. we would like to see all our employees can fulfill their potentials through continually development of examining abilities and performance with us. Our relationships are built on trust, and we hold each other to the highest standards to honour our client-first focus.",
    
};