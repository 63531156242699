import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en_common from "./locales/en/common";
import cn_common from "./locales/zh_CN/common";
import en_home from "./locales/en/home";
import cn_home from "./locales/zh_CN/home";
import en_nav from "./locales/en/nav";
import cn_nav from "./locales/zh_CN/nav";
import en_footer from "./locales/en/footer";
import cn_footer from "./locales/zh_CN/footer";
import cn_about from "./locales/zh_CN/about";
import en_about from "./locales/en/about";
import cn_service from "./locales/zh_CN/service";
import en_service from './locales/en/service';
import cn_invest from "./locales/zh_CN/invest";
import en_invest from './locales/en/invest';
import cn_products from "./locales/zh_CN/products";
import en_products from './locales/en/products';
import cn_wealth from "./locales/zh_CN/wealth";
import en_wealth from './locales/en/wealth';
import cn_insight from "./locales/zh_CN/insight";
import en_insight from './locales/en/insight';
import cn_account from './locales/zh_CN/account';
import en_account from './locales/en/account';
import cn_loan from './locales/zh_CN/loan';
import en_loan from './locales/en/loan';

// the translations
const resources = {
  "en": {
      common: {
          ...en_common
      },
      about: {
        ...en_about
      },
      home: {
        ...en_home
      },
      nav: {
          ...en_nav
      },
      service: {
        ...en_service
      },
      invest: {
        ...en_invest
      },
      wealth: {
        ...en_wealth
      },
      products: {
        ...en_products
      },
      insight: {
        ...en_insight
      },
      account: {
        ...en_account
      },
      loan: {
        ...en_loan
      },
	  footer: {
		  ...en_footer
	  }
  },
  "zh_CN": {
      common: {
        ...cn_common
      },
      about: {
        ...cn_about
      },
      home: {
        ...cn_home
      },
      nav: {
        ...cn_nav
      },
      service: {
        ...cn_service
      },
      invest: {
        ...cn_invest
      },
      wealth: {
        ...cn_wealth
      },
      products: {
        ...cn_products
      },
      insight: {
        ...cn_insight
      },
      account: {
        ...cn_account
      },
      loan: {
        ...cn_loan
      },
	  footer: {
		...cn_footer
	}
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    fallbackLng: ["en","zh_CN"],
    useLocalStorage: true,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18next;
