import { SET_LOADING } from "../actions";

const initalState = {
    loading: false,
  };
const LoadingReducer = (state = initalState, action) => {

  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default LoadingReducer;