export default {
    mBenefit: "Member Benefits",
    mIntro: "The highest priority right to know and investment opportunities for investment projects \n Participate in Haili's monthly afternoon tea event for free \n Free participation in Beaver’s annual dinner celebration \n Free participation in Beaver’s project launch and closing dinner celebration \n Discounts at well-known restaurants mainly in Sydney \n Regular meetings and gatherings with wealthy and celebrities \n Three days discount tour and private plane transfer service to a 1 million square meters island with beautiful scenery in Melbourne \n Customized monthly activities: fishing, karaoke, golf, yachting, intercontinental tours, overseas tours, hiking, etc \n",

    mAccount: "My Account",
    bid: "Beaver ID: ",
    name: "Name: ",
    email: "Email: ",
    phone: "Phone: ",
    birth: "Birthday: ",
    password: "Password: ******",
    p: "Password",
    pConfirm: "Confirmation",
    change: "Change Information",
    iProjects: 'Investment project and amount: ',
    fProjects: "Financing project and amount: ",
    fName: "Fund Name",
    tAmount: "Total Amount",
    aIncome: "Accumulated Income",
    aCommission: "Accumulated Commission",
    total: "Total",
    signout: "Sign Out",

    mProfile: "Commission Overview: ",
    eCommission: "Expected Commission: ",
    cName: 'Customer Name',
    sTime: 'Start Time',
    tIncome: 'Customer\'s total income',
    cRate: 'Commission Rate',
    cAmount: 'Commission amount',
    mDetail: "Monthly Detail",
    mCommission: "Monthly Accumulated Commission: ",
    nTime: "Next Payment Time: ",

    fDescription: "Fund Description: ",
    fType: "Fund Type",
    iType: "Investment Type",
    pType: "Product Type",
    minAmount: "Minimum Investment Amount",
    iCycle: "Investment Cycle",
    fixReturn: "Fixed Net Return",
    floatReturn: "Floating Net Return",
    aFee: "Application Fee",
    mFee: "Management Fee",
    nReturnA: "Expected Type A Net Return",
    nReturnB: "Expected Type B Net Return",
    cCycle: "Commission Cycle",
    pFee: "Performance Fee",
    iStrategy: "Investment Strategy",
    nyReturn: "Natural Selection Fund Expected Yearly Return",
    vyReturn: "Value Investment Fund Expected Yearly Return",
    sFee: "Subscription Fee",
    iDetail: "Investment Detail",
    iAmount: "Investment Amount: ",
    iTime: "Investment Date: ",
    eTime: 'Expired Date: ',
    eReturn: 'Expected Total Return: ',
    cTReturn: "Total Return until now: ",
    mTBenefit: "Monthly Benefit: ",
    hRecord: "History Payment Record",
    fInfo: "Fund Information IM (PDF)",
    fIntro: "Fund Introduction (PDF)",
    reInvest: "Reinvest Form (PDF)",
    fReport: "Fund Report (PDF)",
    eoi: "EOI Form (PDF)",
    warning: "Warning",
    noRecord: "Your investment is less than a month, no historical records have been generated yet",

    time: "Time",
    location: "Location",
    aDescription: "Activity Description",
    end: "Ended",
    register: "Register",
    rParticipate: "Register to participate",
    rParticipant: "Number of participants",

    rnVerification: "Real-Name Verification",
    dTime: "Dividend Time",
    dAmount: "Dividend Amount",

    submitVerify: "Submit Verification",
    document: "Document",

    online: "Online",
    sydney: "Sydney",
    melbourne: "Melbourne",
    brisbane: "Brisbane",
    perth: "Perth",
    adelaide: "Adelaide",
    canberra: "Canberra",
    hobart: "Hobart",
    goldcoast: "Gold Coast",
    otherCities: "Other Cities",

    nextStep: "Next Step",
    complete: "Complete",
    noInvestorType: "Please select the type of your investment",
    sdkFail: "SDK initialization failed, please refresh the page and try again",
    verifySuccess: "Verification successfully submitted, please wait for the administrator to review",
    verifyFail: "Verification failed, please try again",

    country: "Country",
    documentType: "Document Type",
    livePhoto: "Live Photo",
    uploadDocuments: "Upload Documents",
    verifyFinish: "Verification Finished",
    finishNotice: "Your verification has been submitted, please wait for the administrator to review. Thanks for choosing Beaver Capital!",
	verifyWaiting: 'Verification Waiting',
	verifyApproved: 'Verification Approved',
	verifyNotMatch: 'Verification Not Match',
    ComingSoon:"Coming Soon",
    NowOpen:"Now Open",
    FullyInvested:"Fully Invested",
    Completed:"Completed",
    mTips: "During the current system upgrade, the interest calculation displayed might be incorrect. \n Please only refer to the correct amount on the Monthly Distribution Statement you received.",
}