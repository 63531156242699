import { combineReducers } from "redux";
import LoginReducer from "./login";
import LoadingReducer from './loading';
import FrameReducer from "./frame";
import LanguageReducer from './language'

const rootReducer = combineReducers({
  login: LoginReducer,
  loading: LoadingReducer,
  frame: FrameReducer,
  language: LanguageReducer
});

export default rootReducer;
