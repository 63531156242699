import "./footer.scss";
import { useState, useEffect } from "react";
import { Row, Col, Navbar, Container, Modal } from "react-bootstrap";
import logo from "@src/assets/images/logo.png";
import footer from "@src/assets/images/footer.png";
import wechat from "@src/assets/images/wechat.jpeg";
import { RiFacebookFill, RiYoutubeFill, RiWechat2Fill,RiLinkedinBoxFill,RiBookOpenFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { t } = useTranslation(["common, footer"]);
  const faceBook = process.env.REACT_APP_FACE_BOOK_URL;
  const youtube = process.env.REACT_APP_YOUTUBE_URL;
  const linkedin = "https://www.linkedin.com/company/beaver-capital-pty-ltd/about/";
  const redBook = "https://www.xiaohongshu.com/user/profile/60937c8e000000000100104e";
  const openFb = () => {
    window.open(faceBook);
  };
  const openYt = () => {
    window.open(youtube);
  };
  const openLI = () => {
    window.open(linkedin)
  }
  const openRedBook = () => {
    window.open(redBook)
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
	  console.log(process.env)
  }, []);
  return (
    <Navbar
      sticky="bottom"
      style={{
        backgroundColor: "white",
        position: "relative",
        paddingBottom: "4em",
      }}
    >
      <Container style={{ paddingTop: "5em", flexDirection: "column" }}>
        <Row className="full-width bottom-15">
          <Col md={4} xs={12}>
            <img src={footer} alt="footer-img" className="img-fit" />
          </Col>
          <Col md={8} xs={12} style={{ textAlign: "right", color: "#393838" }}>
            <img
              src={logo}
              alt="beaver-logo-footer"
              style={{ width: "130px" }}
            />
            <div className="divider" />
            <p>AFSL: 306534</p>
            <p>{t("common:phone")}: (02) 7908 3237</p>
            <p>{t("common:email")}: operations@beavercapital.com.au</p>
            <div className="text-right" style={{ marginTop: "25px" }}>
              <button
                type="button"
                className="btn btn-secondary round-btn"
                onClick={openFb}
                style={{ padding: "0" }}
              >
                <RiFacebookFill />
              </button>
              <button
                type="button"
                className="btn btn-secondary round-btn"
                onClick={openYt}
                style={{ padding: "0" }}
              >
                <RiYoutubeFill />
              </button>
              <button
                type="button"
                className="btn btn-secondary round-btn"
                onClick={handleShow}
                style={{ padding: "0" }}
              >
                <RiWechat2Fill />
              </button>
              <button
                type="button"
                className="btn btn-secondary round-btn"
                onClick={openLI}
                style={{ padding: "0" }}
              >
                <RiLinkedinBoxFill />
              </button>
              <button
                type="button"
                className="btn btn-secondary round-btn"
                onClick={openRedBook}
                style={{ padding: "0" }}
              >
                <RiBookOpenFill />
              </button>
            </div>
          </Col>
          <Modal show={show} onHide={handleClose}>
            <img
              src={wechat}
              alt="wechat-icon"
              style={{ width: "400px", margin: "auto" }}
            />
          </Modal>
        </Row>
        <Row className="full-width">
          <Col md={6} sm={12}>
            <p
              className="font-yellow"
              style={{ fontWeight: "600", marginBottom: "5px" }}
            >
              GET DIRECTION
            </p>
            <div style={{ height: "1px", backgroundColor: "#707070" }}></div>
          </Col>
          <Col md={6} sm={12} className="text-right mobile-only">
            <div
              style={{
                color: "#707070",
                marginTop: "16px",
                marginBottom: "0",
                fontSize: "13px",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <span style={{ marginBottom: "8px" }}>
                <a href="#home" style={{ color: "#707070" }}>
                  {t("footer:termsConditions")}
                </a>{" "}
                /
                <a href="#home" style={{ color: "#707070" }}>
                  {t("footer:privacyPolicy")}
                </a>{" "}
                /
                <a href="#home" style={{ color: "#707070" }}>
                  {t("footer:cookies")}
                </a>
              </span>
              © 2021 Beaver Capital. All rights reserved
            </div>
          </Col>
          <Col md={6} sm={7} className="text-center pc-only" style={{display: 'flex', justifyContent: 'end'}}>
            <div
              style={{
                color: "#707070",
                marginTop: "16px",
                marginBottom: "0",
                fontSize: "13px",
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'flex-end'
              }}
            >
              © 2021 Beaver Capital. All rights reserved
              <p style={{ marginLeft: "15px" }}>
                <a href="#home" style={{ color: "#707070" }}>
                  {t("footer:termsConditions")}
                </a>{" "}
                /
                <a href="#home" style={{ color: "#707070" }}>
                  {t("footer:privacyPolicy")}
                </a>{" "}
                /
                <a href="#home" style={{ color: "#707070" }}>
                  {t("footer:cookies")}
                </a>
              </p>

            </div>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};
export default Footer;
