export default {
  wealthClub: "Beaver Wealth Club",
  btext: "Beaver Wealth Club",
  intro: "is a platform that gathers the elites and high net worth individuals from various industries, to service investors from the Beaver Capital fund and other high-end revenue institutions with asset allocation requirements. It aims to assist members to create or promote complex industry exchanges, share investment experiences and to provide an exclusive platform which customizes activities for members to facilitate business information exchange and cooperation. We have a very established partnership with many international top wealth management teams and financial institutions to provide members with very well curated financial and business courses and exclusive wealth management solutions.  Furthermore, there are customized leisure and entertainment activities such as private receptions, vacation trips, yacht parties and the list goes on.",
  mbTitle: "Member Benefits",
  mbDescription: "Priority-listed for information on new investment opportunities. \n Free admission to Beaver’s monthly afternoon tea events. \n Free admission to Beaver’s annual gala dinner. \n Free admission to Beaver’s project fundraising completion and project exit celebration dinners. \n Receive discounts at famously renowned local eateries and restaurants across Sydney. \n Opportunity to network with industry experts and high-profile guests. \n 3-day holiday on a million square meter luxuriously secluded island with amazing scenic views off the coast of Melbourne including air transport. \n Monthly customized activities including fishing, wine tasting, golfing, yachting, intercontinental/overseas travel, hiking and so much more.",
  jcTitle: "Joining Conditions",
  jcDescription: "Investors who purchase Beaver’s fund products, 1 quota \n Investors with Investment amount above 200,000 Australian dollars, 2 quotas \n Partners who signed a contract and successfully established cooperation channel with Beaver, 2 quotas \n Pension investment customers, 1 quota \n Media partners, 1 quotabr> Professionals in various industries (with national-className qualification certificates) \n Members who pay an annual membership fee of $2000",
  recentAct: "Recent Activities",
  moreAct: "More Activities",
}
