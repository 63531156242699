/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  visionOfBeaverTitle: "海利资产贷款 (BABL)",
  visionOfBeaverText:
    "海利资产贷款 (BABL) 使企业能够腾出资产用于营运资金、资本支出或其他资金需求。借助BABL，您公司的各种资产可以用作抵押品，从而释放所需的资金。",
  whoWeAreTitle: "海利金控",
  whoWeAreText:
    "海利金控旗下的基金核心团队拥有累计超过100年的金融服务和基金管理经验，持有澳洲金融服务牌照。专注于发行并运营抵押类基金、地产金融、资产配置、财务管理、金融咨询等领域，我们结合在亚洲和澳大利亚的投资经验和竞争优势，将更多的投资机会提供给客户。",
  fundsTitle: "海利基金",
  fundsText:
    "海利基金专注于发行并运营抵押类基金、地产金融、资产配置、财务管理、金融咨询等领域，满足广大投资者不同的投资需求。",
  principleAndStrategyTitle: "海利资产贷款 (BABL)",
  principleAndStrategyText:
    "海利资产贷款 (BABL) 使企业能够腾出资产用于营运资金、资本支出或其他资金需求。",
  investWithUsTitle: "投资我们",
  investWithUsText:
    "我们管理多种投资策略和多种类别的基金产品。将不同特点的基金产品带给投资者。我们的目标是实现所有合作伙伴的共赢。",
  marketTitle: "市场话题分析及资讯",
  beaverForum: "海利大讲堂",
  quickNews: "海利财经快讯",
};
