export default {
  bcn: "Beaver Capital News",
  bcns: "Beaver Forum",
  gww: "Beaver Financial Express",
  lin: "Latest Financial Express",

  gTitle: "Beaver Financial News",
  gsubTitle: "重大财经日历前瞻 • 本周事件重大回顾 • 海利机构交易策略分享",

  gNews: "BEAVER ",
  gNo: "No.{{number}}",
  gView: "VIEW",

  intro: "Beaver Group Holdings keeps close track to the latest market dynamics and thoroughly analyzes market and economic conditions. We endeavour to educate our clients to offer a deeper insight and understanding into our investment strategy and assist our clients in improving their financial quotient through our analysis of industry trends, economic indicators and hot topics to become a better investor.",
}
