export default {
    fundManagement: "Fund management",
    fundService: "Fund service",
    financialConsultation: "Financial consultation",
    wealthCreationPlatform: "Wealth creation platform",

    p1Title: "BC Debt income opportunities fund",
    p1subTitle: "The fund provides an opportunity for investors to gain access, with a single investment, into a diversified portfolio of debt securities, sourced by the investment manager and predominantly secured by mortgages over both commercial and residential real estate in Australia. Whilst the investment manager is sourcing potential investment opportunities.",
    pt1: "Trust Structure: ",
    pt2: "Investors: ",
    pt3: "Minimum Investments: ",
    pt4: "Minimum Investment Period: ",
    pt5: "Target Net Return(after costs, fees): ",
    pt6: "Distributions: ",
    pt7: "Unit Price: ",
    pt8: "Floating return: ",
    pt9: "Application Fee: ",
    pt10: "Management Fee: ",

    p1d1: "Wholesale unit trust",
    p1d2: "Wholesale investors(only)",
    p1d3: "$500,000(AUD)",
    p1d4: "9 months",
    p1d5: "Fixed 8% per annum",
    p1d6: "Monthly",
    p1d7: "$1.00/Unit",

    p2Title: "BC Industry fund",
    p2subTitle: "The fund including property fund and agriculture fund. We primarily focu on traditional property projects and property development projects right across Australia, the fund maintains partnership with top tier developers from time to time. Given the nature of property investment, the fund will not be liquid for redemptions. The agriculture fund will include raw material production and cleaner energy approach related concept. \n Adelaide CBD Landmark Investment Fund \n The purpose of the fund is to offer investors an opportunity to participate in the Adelaide Central Market Arcade Redevelopment Project. Investment is in the form of loans with fixed return paid quarterly and floating return paid at redemption upon maturity based on performance of the fund. \n Key investment themes: \n 1. Adelaide residential market exhibits long-term stability \n 2. Government partnership and staged development \n 3. Fund capital preservation a priority \n 4. Commercial real estate market is undergoing revitalization \n 5. Core asset managed by an experienced team \n 6. Robust risk management and governance",

    p2d1: "Wholesale unit trust",
    p2d2: "Wholesale investors(only)",
    p2d3: "$50,000(AUD)",
    p2d4: "48-60 months",
    p2d5: "10% - 15% per annum",
    p2d6: "Fixed return: (5%) is distributed quarterly",
    p2d7: "Calculated and distributed at redemption upon maturity",
    p2d8: "$1.00/Unit",
    p2d9: "1.50%",
    p2d10: "2%",

    p3Title: "BC long-short opportunities fund",
    p3subTitle: "Our strategy is to invest in a portfolio of financial instruments in both domestic and global markets. The trust will focus on mispricing opportunities in individual currencies, indices and underlying stocks. The trust has target net returns of 20% per annum(after fees and other expenses) and aims to provide clients with quarterly distributions, capital preservation and portfolio diversification.",

    p3d1: "Wholesale unit trust",
    p3d2: "Wholesale investors(only)",
    p3d3: "$50,000(AUD)",
    p3d4: "6 months",
    p3d5: "20% per annum",
    p3d6: "Quarterly(if any)",
    p3d7: "$1.00/Unit",
    p3d8: "0.50%",
    p3d9: "1.50%",
    p3d10: "30%",

    p4Title: "BC venture capital",
    p4subTitle: "The fund provides an opportunity for investors to gain access, with a single investment, into a diversified portfolio of debt securities, sourced by the investment manager and predominantly secured by mortgages over both commercial and residential real estate in Australia. Whilst the investment manager is sourcing potential investment opportunities.",
    p4d1: "Arthur Street Capital Management",
    p4d2: "Arthur Street Capital Management Pty Ltd is a private company incorporated in Australia on 17 December 2018. It was established by a group of leading investment professionals, and offers venture capital fund to investors. The fund’s objectives are to seek to invest in firms that have high-risk/high-return profiles, based on a company‘s size, assets and stage of product development and also to create and add exceptional value to investors’ wealth. With extensive collective experience and expertise gained in both the corporate and financial worlds, Arthur Street Capital Management is able to offer its clients thought leadership and original investment ideas that set Arthur Street Capital Management apart from average investment house.",
    p4d3: "BCU",
    p4d4: "The BCU incubator establishes an industrial park and sets up a special fund in Australia. By providing high-quality start-up teams with site support, technical support, industry resource guidance, and financial support. We integrate the industrial resources of China and Australia to create a better entrepreneurial environment from a global perspective, and aim to help Australian startups expand into China and other overseas markets.",

    ourService: "Our service",
    serviceIntro: "Beaver Capital is a specialized fund manager providing full suite of financial services, includes investment funds, fund services, financial service consultation and wealth creation platform. Our professional teams can offer financial solutions and comprehensive services to both boutique fund manager or investors, each team hold each other on to the highest standards to honour our client-first focus.",
    bcvc: "BC Venture Capital" ,
    bcdiof: "BC Debt Income Opportunities Fund",
    bclof: "BC long-short opportunities fund",
    bcif: "BC Industry fund",
    fundRegistry: "Fund Registry",
    fundAdministration: "Fund Administration",
    custodyServices: "Custody Services",
    trusteeService: "Trustee Service",
    fsConsulting: "Financial Services Consulting",
    csConsulting: "Corporate Strategy Consulting",
    eConsulting: "Entrepreneurial Consulting",
    assetAllocation: "Asset Allocation",
    bcwealthClub: "BC Wealth Club",
};
