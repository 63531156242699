/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    who:"海利金控",
    whoContent1: "海利金控是一家专业的基金投资和基金管理公司。坐落于澳大利亚，悉尼。",
    whoContent2: "海利金控旗下的基金核心团队拥有累计超过100年的金融服务和基金管理经验，持有澳洲金融服务牌照。专注于发行并运营抵押类基金、地产金融、资产配置、财务管理、金融咨询等领域，我们结合在亚洲和澳大利亚的投资经验和竞争优势，将更多的投资机会提供给客户。",
    whoContent3: "我们重视客户的参与和建议，力求通过我们的专业服务达到客户的投资期望。",
    what: "公司简介",
    whatContent1: "海利金控旗下的海利基金发行并运营多种基金，为金融机构，金融行业咨询人士和大众投资者提供高水准的金融服务以及解决方案。",
    whatContent2: "我们的目标是根据客户的实际情况，通过具体方案帮助更多的客户实现他们长期的理财目标。海利金控基金管理团队致力于将可持续发展的社会价值观和公司的商业活动和投资理念相结合，为整个澳大利亚社区带来积极的影响。",

    people: "海利团队",
    wwu: "加入我们",
    pwu: "合作",

    storyTitle: "企业历史",
    story1: "海利基金（AFSL:306534）自2006年成立以来就为投资者提供投资方案以达到其投资目标。",
    story2: "核心团队曾参与并主导悉尼市中心的多项大型住宅开发项目，并曾代表海外金融机构收购和合并悉尼市中心的大型商业建筑和酒店，交易量超过10亿澳元。",
    story3: "通过整个专业团队的多年努力，以及多年的专业知识和实践经验，海利基金目前为投资者提供多种特性的基金产品以满足不同投资者的需求，包括债券基金，对冲基金，产业基金，数字货币基金以及风险投资基金。",
    story4: "海利基金专注于把最好的投资资源带给投资人，帮助投资人获得长期可持续的收益。",

    hansStory: "Hans Han有超过10年的地产并购和移民服务经验，参与并主导了多项在悉尼、墨尔本以及阿德莱德的大型地标性地产开发项目。Hans和他的团队在政府的重大投资移民计划中发挥着不可或缺的作用并管理着超过5000万澳元的资金。他曾与一线的房地产公司合作，并收购了悉尼多个标志性建筑，帮助他的客户从亚太地区平稳过渡到澳大利亚地产行业。Hans职业初期阶段在中国北京的一家著名律师事务所总部担任商业律师，拥有 15年的基金管理和法务经验，为海利基金带来巨大财富。",
    emmaStory: "Emma曾在中国和新加坡多家大型机构任职，拥有15年的高级管理经验。职业生涯初期，Emma在世界500强的中国移动通信公司担任大区经理，带领并管理200余名职工，负责多项核心业务的运营。Emma热衷于创新项目的投资，她参与并主导了多个创业孵化项目，并在其中扮演重要角色，为多个创业公司的管理团队提供持续的帮助和指导。多年的团队管理以及项目运营经验使Emma具备了超强的执行力，以及追求实现极致用户体验的高水准产品运营能力。Emma目前在亚太地区金融投资协会担任主席一职。",
    andyStory: "Andy拥有多元化的金融专业职业背景，在私人银行、基金管理、和投资银行以及商业领域担任了 20 多年的高层管理职位。 他在理财投资规划、管理投资信托、企业咨询、风险投资、私募股权、收购兼并等方面拥有资质及深厚的专业知识和经验。在农业、矿业、养老、医疗科技、和地产投资信托领域层面，对标志性项目交易，资产总额超过30 亿澳元。\n Andy融合了中西文化教育背景及中澳及亚太多边跨国境工作业务经验，整合了各产业的知识优势，洞悉国内外市场、澳洲产业规范、投资环境因素，善用丰富产业资源人脉、专业机构渠道。\n Andy除了为集团带来了他非凡的专业和价值，此外，他还贡献了时间来指导新创企业孵化，并提供全球知名的 WSET 葡萄酒、烈酒、清酒教育认证课程。Andy结合他的金融能力及爱好热忱，在过去成功实现了创新精品葡萄酒信托基金项目。",

    support: "全方位服务",
    supContent: "海利基金的成功以及业内良好的声誉源于其高质量的全方位服务。海利基金不仅为我们的合作伙伴提供专业的金融服务，同时也通过系统的行业数据分析，和专业的服务团队为合作伙伴提供咨询，分析，客服，市场推广，法务合规咨询，人事管理，IT以及办公室管理上的支持。",
    win: "实现双赢",
    winContent: "海利基金的目标是给投资人带来更多优质的基金产品，为了实现这一目标，海利基金积极的与各个行业的高水平基金经理及资产管理人展开合作，我们的愿景是，在得到合作伙伴认可和尊重的同时，为投资者和合作伙伴双方带来收益，实现共赢。",
    ourPartner: "合作伙伴",
    partnerContent1: "赤子之心是一家领先、成熟的全球宏观对冲基金管理公司，成立于2003年1月。",
    partnerContent2: "赤子之心深知全球政治和经济的复杂性，经过十几年全球投资探索，创立“全球兴衰投资体系”，使得赤子之心在多变的全球环境中自如航行。赤子之心目前管理着两家对冲基金，分别是2003年和2009年成立的赤子之心价值投资基金和赤子之心自然选择基金。",
    partnerContent3: "赤子之心和海利基金之间的合作将为双方的发展带来双赢局面，这一合作不仅有助于推动原有公司进入和开发新的潜在市场，并且有利于促进新业务新商品的开发。我们的共同目标是与高素质的精品资产管理公司建立合作伙伴关系，并不断地将优质的基金项目传递给客户。如果想了解更多信息，请浏览",

    workCulture: "企业文化",
    cultureContent1: "先进的企业文化使海利基金在行业竞争中保持优势，我们重视沟通的真实性以及透明性，以确保我们可以聆听最好的建议和策略。",
    cultureContent2: "我们提倡多样性，因为我们相信从不同角度思考问题的能力对我们的成长至关重要，我们始终保持对于新知识新信息的饥饿感并寻找新的机会。",
    cultureContent3: "我们重视包容性，因为我们相信，当每个人充分发挥自身个性的时候可以展现出自己最好的一面，为团队带来最大的贡献。",
    sharedValue: "共同价值观",
    valueContent: "我们在业务运营以及团队组建的过程中，非常看重多样性，互相尊重，开放的沟通模式以及不断学习的习惯。我们希望每一位海利基金的成员都可以平衡自己工作和生活的时间，享受每天的工作。我们希望海利基金的每一位员工可以从日常工作和不断学习中激发自己的潜力，并不断提升自己。彼此信任，共同努力，将最好的服务带给海利基金的每一位客户。",
    
};