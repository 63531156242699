export default {
    enquire: "Enquire",
    loanOrigination: "Loan Origination",

    babl: "Beaver Asset Based Lending (BABL)",
    bablIntro: "Beaver Asset Based Lending Facility (BABL) enables businesses to free up their assets for working capital, capital expenditures or other funding needs. With BABL, your company's wide range of assets including accounts receivable, inventory, PP&E and real property can serve as collateral, freeing up needed capital.",

    bcl: "Beaver Construction Loans",
    bclIntro: "Beaver's undisputed expertise in lending rewrites the ways developers do business in the property world. Beaver is incessantly active in lending for a vast array of commercial, industrial, retial and residential projects. Beaver construction loans have a variable rate which depends on lenders.",
    bclSubtitle: "The range of products avaliable includes:",
    bclSubText1: "Land-bank finance with interest capitalised or paid monthly.",
    bclSubText2: "Site acquisition against valuations uplifted by rezoning/planning approvals.",
    bclSubText3: "Progressively drawn construction finance for developer builders or for developers outsourcing construction and covering budgeted construction, construction contingency, project management, consultants costs and holding costs, interest capitalised during construction and the budget selldown period. ",
    bclSubText4: "Structured finance solutions to increase gearing up to as high as 95% of project costs to improve return on equity, release equity to the developer, access higher value projects or provide bridging finance across multiple assets. ",
    bclSubText5: "Take-out/recapitalisation of development residual stock to clear construction loans, release equity or provide an extended selling period",

    bsf: "Beaver Structured Finance",
    bsfText1: "Structured Finance can be likened to the conductor in front of an orchestra of talented single skilled specialists. Beaver is ideally positioned to coordinate each lender through a single term sheet and facility manager and provides the scaffolding and space for major borrowers needing a capital injection or alternative source of financing. Beaver offers a way of saving on time and legal costs.",
    bsfText2: "Beaver ability to stand in the market as an intermediary between the borrower and multiple lenders on a single transaction has ensured the interest of the borrower is protected and transactions are not defeated by poor coordination or a doubling up of costs.",

    bbd: "Beaver Big Deals",

    title1: "Annandale NSW",
    des1: "Sydney CBD Boarding House",
    val1: "Valuation $ 7,500,000",
    time1: "April 2022",

    title2: "Paradise Point QLD",
    des2: "Gold Coast Waterfront Mansion with Jetty",
    val2: "Valuation $ 3,900,000",
    time2: "March 2022",

    title3: "Redfern NSW",
    des3: "CBD Retail & Residence - Mixed Use",
    val3: "Valuation $ 5,300,000",
    time3: "April 2022",

    title4: "Cringila NSW",
    des4: "Resort Hotel Bar & Gaming",
    val4: "Valuation $ 28,000,000",
    time4: "March 2022",

    title5: "Alexandria NSW",
    des5: "Residential - Future Development Potential",
    val5: "Valuation $ 7,680,000",
    time5: "April 2022",

    title6: "Adelaide SA",
    des6: "Industrial Property CBD Location",
    val6: "Valuation $ 2,000,000",
    time6: "March 2022",

    title7: "Alexandria NSW",
    des7: "Retail & Commercial Kitchen",
    val7: "Valuation $ 5,000,000",
    time7: "April 2022",

    title8: "Coorabell NSW",
    des8: "Prime Rural Development Potential",
    val8: "Valuation $ 20,000,000",
    time8: "March 2022",

    title9: "Mount Hunter NSW",
    des9: "International Airport Location",
    val9: "Valuation $ 29,500,000",
    time9: "April 2022",

    title10: "Parwan WA",
    des10: "Petro / Gas Service Station & Cafe Site",
    val10: "Valuation $ 28,000,000",
    time10: "December 2021",

    title11: "Rockingham Beach WA",
    des11: "",
    val11: "Valuation $ 2,630,000",
    time11: "January 2022",

    title12: "Annanndale NSW",
    des12: "Hotel in Sydney",
    val12: "Valuation $ 34,350,000",
    time12: "November 2021",
}