export default {
  wealthClub: "财富俱乐部",
  btext: "海利财富俱乐部",
  intro: "是一个荟聚各行业精英和高净值人士，服务于海利基金客户，以及其他有资产配置需求的高端收入人群的平台。 旨在帮助财富端会员缔造或促进更深层的行业交流、分享投资心得，为会员定制活动实现商业信息交流及合作提供专属平台。 并与众多国际化顶级财富管理团队以及金融机构长期合作，为会员提供海利财商课程和财富端专属理财方案。以及专属的休闲娱乐活动，如度假旅行，游艇派对等。",
  mbTitle: "会员福利",
  mbDescription: "投资项目最优先知情权和投资机会 \n 免费参加海利每月一次的下午茶活动 \n 免费参加海利每年年会晚宴庆祝活动 \n 免费参加海利不定期固定项目募资完成和项目退出庆祝晚宴活动 \n 以悉尼为主的各知名餐厅优惠折扣 \n 定期富豪名人见面聚会 \n 墨尔本100万平方米，风景秀丽独岛优惠旅游三天，专机接送 \n 每月定制活动：钓鱼、卡拉OK、高尔夫、游艇、洲际游以及出国游、徒步等",
  jcTitle: "入会条件",
  jcDescription: "购买海利基金产品的投资人，1个名额 \n 投资金额20万澳币及以上，2个名额 \n 与海利基金签约且成功搭建渠道的合作伙伴，2个名额 \n 养老金投资客户，1个名额 \n 媒体合作商并合作愉快方，1个名额 \n 各行业专业人士（有国家级别资格证书） \n 交每年$2000元会费者",
  recentAct: "近期活动",
  moreAct: "更多活动",
}
