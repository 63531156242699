import "./header.scss";
import {
  Navbar,
  Nav,
  NavDropdown,
  Offcanvas,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import logo from "@src/assets/images/logo.png";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
// useHistory
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const Header = (props) => {
  const { isAuth } = localStorage;
  const { t } = useTranslation(["common", "nav"]);
  const [showAbout, setShowAbout] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const [showService, setShowService] = useState(false);
  const [showInvest, setShowInvest] = useState(false);
  const changeLanguage = () => {
    const changeLoacalLanguage = (value) => {
      localStorage.setItem("i18nextLng", value);
      i18n.changeLanguage(value);
    };
    localStorage.getItem("i18nextLng") === "en"
      ? changeLoacalLanguage("zh_CN")
      : changeLoacalLanguage("en");
  };
  const navigate = useNavigate();

  useEffect(() => {

  },[isAuth])

  return (
    <>
      <div className="grey-background">
        <Navbar expand="lg" style={{ padding: "20px 0" }}>
          <Container>
            <Row className="full-width">
              <Col md={2} xs={10}>
                <Navbar.Brand
                  onClick={() => navigate("../", { replace: true })}
                >
                  <img id="beaver-logo" src={logo} alt="beaver-logo" />
                </Navbar.Brand>
              </Col>
              <Col md={10} xs={2} className="align-self">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="btn-boderless"
                  aria-expanded="true"
                />
                <Row className="full-width">
                  <Nav
                    className="full-width pc-only"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Row className="justify-content-end full-width">
                      <Col md={4} xs={12} className="flex-end">
                        <Nav.Link>
                          <Button
                            variant="link"
                            onClick={changeLanguage}
                            className="font-yellow language-btn"
                          >
                            <span>{t("common:language")}</span>
                          </Button>
                          { isAuth
                          ?
                          <button
                            className="login-btn"
                            onClick={() =>
                              navigate("/account/info", { replace: true })
                            }
                          >
                            <span>{t("common:myAccount")}</span>
                          </button>
                          :
                          <button
                            className="login-btn"
                            onClick={() =>
                              navigate("../login", { replace: true })
                            }
                          >
                            <span>{t("common:login")}</span>
                          </button>
                          }
                        </Nav.Link>
                      </Col>
                    </Row>
                    <Row
                      className="full-width"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Nav className="justify-content-end beaver-nav">
                        <Nav.Link
                          onClick={() => navigate("../", { replace: true })}
                        >
                          {t("nav:home")}
                        </Nav.Link>
                        {/* About */}
                        <NavDropdown
                          show={showAbout}
                          onMouseEnter={()=>setShowAbout(true)}
                          onMouseLeave={()=>setShowAbout(false)}
                          title={t("nav:about")}
                          id="basic-nav-dropdown"
                          onMouseUp={() =>
                            navigate("../about", {
                              replace: true,
                            })
                          }
                        >
                          <NavDropdown.Item
                            onClick={() =>
                              navigate("../about/our-story", {
                                replace: true,
                              })
                            }
                          >
                            {t("nav:ourStory")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() =>
                              navigate("../about/our-people", { replace: true })
                            }
                          >
                            {t("nav:ourPeople")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() =>
                              navigate("../about/partner-with-us", {
                                replace: true,
                              })
                            }
                          >
                            {t("nav:partnerWithUs")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() =>
                              navigate("../about/work-with-us", {
                                replace: true,
                              })
                            }
                          >
                            {t("nav:workWithUs")}
                          </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link
                          onClick={() => navigate("../loan-origination", { replace: true })}
                        >
                          {t("nav:loanOrigination")}
                        </Nav.Link>
                        {/* Loan Origination 
                        <NavDropdown
                          show={showNews}
                          onMouseEnter={()=>setShowNews(true)}
                          onMouseLeave={()=>setShowNews(false)}
                          title={t("nav:loanOrigination")}
                          id="basic-nav-dropdown"
                          onMouseUp={() =>navigate("../loan-origination", { replace: true })}
                        > */}
                          {/* <NavDropdown.Item
                            //onClick={() =>navigate("../insights-and-news/beaver-forum", {replace: true,})}
                          >
                            {t("nav:beaverABL")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            //onClick={() =>navigate("../insights-and-news/global-weekly-wrap",{ replace: true })}
                          >
                            {t("nav:constructionLoans")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            //onClick={() =>navigate("../insights-and-news/latest-insights-and-news",{ replace: true })}
                          >
                            {t("nav:structuredFinance")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            //onClick={() =>navigate("../insights-and-news/beaver-capital-news",{ replace: true }  )}
                          >
                            {t("nav:bigDeals")}
                          </NavDropdown.Item>
                        </NavDropdown>
                        */}
                        {/* Our Services */}
                        <NavDropdown
                          show={showService}
                          onMouseEnter={()=>setShowService(true)}
                          onMouseLeave={()=>setShowService(false)}
                          title={t("nav:ourServices")}
                          id="basic-nav-dropdown"
                          onMouseUp={() =>
                            navigate("../our-services", { replace: true })
                          }
                        >
                          <NavDropdown.Item
                            onClick={() =>
                              navigate("../our-services/funds-management", {
                                replace: true,
                              })
                            }
                          >
                            {t("nav:fundsManagement")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() =>
                              navigate("../our-services/fund-services", {
                                replace: true,
                              })
                            }
                          >
                            {t("nav:fundServices")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() =>
                              navigate(
                                "../our-services/financial-consultation",
                                { replace: true }
                              )
                            }
                          >
                            {t("nav:financialConsultation")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() =>
                              navigate(
                                "../our-services/wealth-creation-platform",
                                { replace: true }
                              )
                            }
                          >
                            {t("nav:wealthCreationPlatform")}
                          </NavDropdown.Item>
                        </NavDropdown>
                        {/* Invest With Us */}
                        <NavDropdown
                          show={showInvest}
                          onMouseEnter={()=>setShowInvest(true)}
                          onMouseLeave={()=>setShowInvest(false)}
                          title={t("nav:investWithUs")}
                          id="basic-nav-dropdown"
                          onMouseUp={() =>
                            navigate("../invest-with-us", { replace: true })
                          }
                        >
                          <NavDropdown.Item
                            onClick={() =>
                              navigate("../invest-with-us/why-invest-with-us", {
                                replace: true,
                              })
                            }
                          >
                            {t("nav:whyInvestWithUs")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() =>
                              navigate("../invest-with-us/how-to-invest", {
                                replace: true,
                              })
                            }
                          >
                            {t("nav:howToInvest")}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() =>
                              navigate("../invest-with-us/frequent-questions", {
                                replace: true,
                              })
                            }
                          >
                            {t("nav:frequentQuestions")}
                          </NavDropdown.Item>
                        </NavDropdown>
                        {/* Wealth Club */}
                        <Nav.Link
                          onClick={() => navigate("../wealth-club", { replace: true })}
                        >
                          {t("nav:wealthClub")}
                        </Nav.Link>
                      </Nav>
                    </Row>
                  </Nav>
                  <Navbar.Offcanvas
                    className="mobile-only"
                    style={{ width: "70%", padding: "20px" }}
                    placement="end"
                  >
                    <Offcanvas.Header
                      className="flex-end"
                      closeButton
                    ></Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav
                        className="full-width"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Row
                          className="full-width"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Nav className="justify-content-end beaver-nav">
                            <Nav.Link onClick={changeLanguage}>
                              {t("common:language")}
                            </Nav.Link>
                            { isAuth
                            ?
                            <Nav.Link
                              onClick={() =>
                                navigate("/account/info", { replace: true })
                              }
                            >
                              {t("common:myAccount")}
                            </Nav.Link>
                            :
                            <Nav.Link
                              onClick={() =>
                                navigate("/login", { replace: true })
                              }
                            >
                              {t("common:login")}
                            </Nav.Link>
                            }
                            <Nav.Link
                              onClick={() => navigate("/", { replace: true })}
                            >
                              {t("nav:home")}
                            </Nav.Link>
                            {/* About */}
                            <Nav.Link onClick={() => navigate("/about", { replace: true })}>{t("nav:about")}</Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/about/our-story", { replace: true })}>
                              {t("nav:ourStory")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/about/our-people", { replace: true })}>
                              {t("nav:ourPeople")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/about/partner-with-us", { replace: true })}>
                              {t("nav:partnerWithUs")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/about/work-with-us", { replace: true })}>
                              {t("nav:workWithUs")}
                            </Nav.Link>
                            {/* Insights & News */}
                            <Nav.Link onClick={() => {navigate("/loan-origination", { replace: true })}}>
                              {t("nav:loanOrigination")}
                            </Nav.Link>
                            {/* <Nav.Link className="nav-margin-left" onClick={() => navigate("/insights-and-news/beaver-forum", { replace: true })}>
                              {t("nav:beaverABL")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/insights-and-news/global-weekly-wrap", { replace: true })}>
                              {t("nav:constructionLoans")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/insights-and-news/latest-insights-and-news", { replace: true })}>
                              {t("nav:structuredFinance")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/insights-and-news/beaver-capital-news", { replace: true })}>
                              {t("nav:bigDeals")}
                            </Nav.Link> */}
                            {/* Our Services */}
                            <Nav.Link onClick={() => navigate("/our-services", { replace: true })}>
                              {t("nav:ourServices")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/our-services/funds-management", { replace: true })}>
                              {t("nav:fundsManagement")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/our-services/fund-services", { replace: true })}>
                              {t("nav:fundServices")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/our-services/financial-consultation", { replace: true })}>
                              {t("nav:financialConsultation")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/our-services/wealth-creation-platform", { replace: true })}>
                              {t("nav:wealthCreationPlatform")}
                            </Nav.Link>
                            {/* Invest With Us */}
                            <Nav.Link onClick={() => navigate("/invest-with-us", { replace: true })}>
                              {t("nav:investWithUs")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/invest-with-us/why-invest-with-us", { replace: true })}>
                              {t("nav:whyInvestWithUs")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/invest-with-us/how-to-invest", { replace: true })}>
                              {t("nav:howToInvest")}
                            </Nav.Link>
                            <Nav.Link className="nav-margin-left" onClick={() => navigate("/invest-with-us/frequent-questions", { replace: true })}>
                              {t("nav:frequentQuestions")}
                            </Nav.Link>
                            {/* Wealth Club */}
                            <Nav.Link onClick={() => navigate("/wealth-club")}>
                              {t("nav:wealthClub")}
                            </Nav.Link>
                          </Nav>
                        </Row>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Row>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </div>
    </>
  );
};
export default Header;
