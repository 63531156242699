/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	home: 'Home',
	about: 'About',
	ourStory: 'Our story',
	ourPeople: 'Our people',
	partnerWithUs: 'Partner With Us',
	workWithUs: 'Work With Us',
	insightsAndNews: 'Insights & News',
	beaverForum: 'Beaver Forum',
	globalWeeklyWrap: 'Global weekly wrap',
	latestInsightsAndNews: 'Latest insights & news',
	beaverCapitalNews: 'Beaver Capital news',
	ourServices: 'Beaver Financial Products',
	fundsManagement: 'Beaver Capital Funds',
	fundServices: 'Beaver Financial services',
	financialConsultation: 'Beaver Financial consultating',
	wealthCreationPlatform: 'Beaver Fortune Club',
	investWithUs: 'Invest With Us',
	whyInvestWithUs: 'Why invest with us',
	howToInvest: 'Start invest',
	frequentQuestions: 'Frequent questions',
	wealthClub: 'Wealth Club',
	pInfo: "Personal Information",
	pWealth: "Wealth appreciation",
	pFunds: "Investing Funds",
	pOthers:"Other Fund Products",
	pActivities:"Recent Activities",
	loanOrigination: "Loan Origination",
	beaverABL: "Beaver Asset Based Lending",
	constructionLoans: "Construction Loans",
	structuredFinance: "Structured Finance",
	bigDeals: "Big Deals",
}
