import { Component } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function Loading(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loading);

  Component.prototype.$dispatch = dispatch;

  return <>{loading ? <div className="fh5co-loader"></div> : <>{children}</>}</>;
}