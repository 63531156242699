import { SET_LANGUAGE } from "../actions";
const initalState = {
  language: "en",
};
const LanguageReducer = (state = initalState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default LanguageReducer;
