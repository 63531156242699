import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { withTranslation } from "react-i18next";
import loadable from "@loadable/component";
import configureStore from "./configureStore";

import "./App.scss";
import Header from "@src/components/Header/Header";
import Footer from "@src/components/Footer/Footer";
import Loading from "@src/components/Loading/Loading";
import WealthClub from "./pages/WealthClub/WealthClub.jsx";

// Account
const Login = loadable(() => import("@src/pages/Login/Login"));
const ForgotPassword = loadable(() => import("@src/pages/ForgotPassword/ForgotPassword"));
const Account = loadable(() => import("@src/pages/Account/Account"));
const NewsDetail = loadable(() => import("@src/pages/NewsDetail/NewsDetail"));

//Home
const Home = loadable(() => import("@src/pages/Home/Home"));


// About
const About = loadable(() => import("@src/pages/About/About"));
const OurStory = loadable(() => import("@src/pages/About/OurStory"));
const OurPeople = loadable(() => import("@src/pages/About/OurPeople"));
const PartnerWithUs = loadable(() => import("@src/pages/About/PartnerWithUs"));
const WorkWithUs = loadable(() => import("@src/pages/About/WorkWithUs"));

//Loan Origination
const LoanOrigination = loadable(() => import("@src/pages/loanOrigination/LoanOrigination"));

// Insights & News
const InsightsAndNews = loadable(() =>import("@src/pages/InsightsAndNews/InsightsAndNews"));
const BeaverForum = loadable(() =>import("@src/pages/InsightsAndNews/BeaverForum"));
const GlobalWeeklyWrap = loadable(() =>import("@src/pages/InsightsAndNews/GlobalWeeklyWrap"));
const LastestInsightsAndNews = loadable(() =>import("@src/pages/InsightsAndNews/LastestInsightsAndNews"));
const BeaverCapitalNews = loadable(() =>import("@src/pages/InsightsAndNews/BeaverCapitalNews"));

// Our Services
const OurServices = loadable(() =>import("@src/pages/OurServices/OurServices"));
const FundsManagement = loadable(() =>import("@src/pages/OurServices/FundsManagement"));
const FundServices = loadable(() =>import("@src/pages/OurServices/FundServices"));
const FinancialConsultation = loadable(() =>import("@src/pages/OurServices/FinancialConsultation"));
const WealthCreationPlatform = loadable(() =>import("@src/pages/OurServices/WealthCreationPlatform"));


// Invest With Us
const InvestWithUs = loadable(() =>import("@src/pages/InvestWithUs/InvestWithUs"));
const WhyInvestWithUs = loadable(() =>import("@src/pages/InvestWithUs/WhyInvestWithUs"));
const HowToInvest = loadable(() =>import("@src/pages/InvestWithUs/HowToInvest"));
const FrequentQuestions = loadable(() =>import("@src/pages/InvestWithUs/FrequentQuestions"));

// Terms&Conditions Privacy Policy
const TermsAndConditions = loadable(() =>import("@src/pages/TermsAndConditions/TermsAndConditions"));

// Privacy Policy
const PrivacyPolicy = loadable(() =>import("@src/pages/PrivacyPolicy/PrivacyPolicy"));

const App = (props) => {
  const { store } = configureStore({});

  const ProtectedRoute = ({ children }) => {
    const {isAuth} = localStorage
    if (!isAuth) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <Provider store={store}>
      <div id="page">
        <Loading>
          <Router>
            <Header />
            <Routes>
              <Route exact path="/" element={<Home />} />
              {/* About */}
              <Route exact path="/about" element={<About />} />
              <Route exact path="/about/our-story" element={<OurStory />} />
              <Route exact path="/about/our-people" element={<OurPeople />} />
              <Route
                exact
                path="/about/partner-with-us"
                element={<PartnerWithUs />}
              />
              <Route
                exact
                path="/about/work-with-us"
                element={<WorkWithUs />}
              />
              {/* Loan Origination */}
              <Route exact path="/loan-origination" element={<LoanOrigination />} />
              {/* news detail*/}
              <Route exact path="/news/:id" element={<NewsDetail />} />
              {/* InsightsAndNews */}
              <Route
                exact
                path="/insights-and-news"
                element={<InsightsAndNews />}
              />
              <Route
                exact
                path="/insights-and-news/beaver-forum"
                element={<BeaverForum />}
              />
              <Route
                exact
                path="/insights-and-news/global-weekly-wrap"
                element={<GlobalWeeklyWrap />}
              />
              <Route
                exact
                path="/insights-and-news/latest-insights-and-news"
                element={<LastestInsightsAndNews />}
              />
              <Route
                exact
                path="/insights-and-news/beaver-capital-news"
                element={<BeaverCapitalNews />}
              />
              {/* OurServices */}
              <Route exact path="/our-services" element={<OurServices />} />
              <Route
                exact
                path="/our-services/funds-management"
                element={<FundsManagement />}
              />
              <Route
                exact
                path="/our-services/fund-services"
                element={<FundServices />}
              />
              <Route
                exact
                path="/our-services/financial-consultation"
                element={<FinancialConsultation />}
              />
              <Route
                exact
                path="/our-services/wealth-creation-platform"
                element={<WealthCreationPlatform />}
              />
              {/* InvestWithUs */}
              <Route exact path="/invest-with-us" element={<InvestWithUs />} />
              <Route
                exact
                path="/invest-with-us/why-invest-with-us"
                element={<WhyInvestWithUs />}
              />
              <Route
                exact
                path="/invest-with-us/how-to-invest"
                element={<HowToInvest />}
              />
              <Route
                exact
                path="/invest-with-us/frequent-questions"
                element={<FrequentQuestions />}
              />
			  {/* WealthClub */}
              <Route exact path="/wealth-club" element={<WealthClub />} />
			  {/* Login */}
			  <Route exact path="/login" element={<Login />} />
			  {/* ForgotPassword */}
			  <Route exact path="/forgot-password" element={<ForgotPassword />} />
			  {/* Terms&Conditions */}
			  <Route exact path="/terms-conditions" element={<TermsAndConditions />} />
			  {/* Privacy Policy */}
			  <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        {/*Account */}
        <Route exact path="/account/*"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />

            </Routes>
            <Footer />
          </Router>
        </Loading>
      </div>
    </Provider>
  );
};
export default withTranslation()(App);
