export default {
  bcn: "海利大讲堂",
  bcns: "海利动态",
  gww: "海利财经快讯",
  lin: "财经资讯",

  gTitle: "海利财经快讯",
  gsubTitle: "重大财经日历前瞻 • 本周事件重大回顾 • 海利机构交易策略分享",

  gNews: "海利财经快讯",
  gNo: "第{{number}}期",
  gView: "查看",

  intro: '海利金控关注市场动态并对市场和经济情况进行分析，我们尽可能的让客户了解我们的投资策略，并通过我们对于行业趋势，经济指标和热门话题的分析，帮助我们的客户提高财商，成为更好的投资者。',
}
