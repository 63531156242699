import { Container, Row, Col } from "react-bootstrap";
import "./wealthClub.scss";
import img1 from "@src/assets/images/wealth1.jpg";
import crown from "@src/assets/images/crown.jpg";
import greyPoint from "@src/assets/images/grey-point.png";
import wealth from "@src/assets/images/wealth.jpg";
import wealth_qr from "@src/assets/images/wealth_qr.jpg";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
const WealthClub = (props) => {
  const { t } = useTranslation(["common", "wealth"]);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  return (
    <>
      <div
        id="fh5co-course-title"
        className="grey-background"
        style={{ position: "relative" }}
      >
        <Container
          style={{ padding: "40px 0 0", position: "relative", zIndex: "100" }}
        >
          <Row>
            <Col sm={12} className="animate-box" style={{
              backgroundImage: `url(${img1})`,
              backgroundPositionY: '35%',
              backgroundPositionX: 'center',
              backgroundSize: 'cover',
              textAlign: 'center',
              aspectRatio: 2.5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span
                style={{
                  color: "white",
                  fontSize: "60px",
                  fontWeight: "600",
                }}>
                {t("wealth:wealthClub")}
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        id="fh5co-blog"
        className="grey-background"
        style={{ position: "relative", paddingBottom: "0", paddingTop: "3em" }}
      >
        <Container
          className="animate-box"
          style={{ position: "relative", zIndex: "100" }}
        >
          <Row>
            <p style={{ margin: "35px 0 70px" }}>
              <b>{t("wealth:btext")}</b> {t("wealth:intro")}
            </p>
          </Row>
        </Container>
      </div>
      <Container
        id="activities"
        style={{ paddingTop: "35px", paddingBottom: "20px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>{t("wealth:recentAct")}</h3>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h3>-{">"} </h3>
            <a>
              <h3 className="font-yellow" style={{ cursor: "pointer" }} onClick={()=> navigate('/account/activities')}>
                {t("wealth:moreAct")}
              </h3>
            </a>
          </div>
        </div>
      </Container>
      <div
        id="fh5co-blog"
        className="grey-background"
        style={{ position: "relative", paddingBottom: "0", paddingTop: "3em" }}
      >
        <Container
          className="grey-background grey-shadow animate-box"
          style={{ position: "relative", zIndex: "100", padding: "30px" }}
        >
          <Row>
            <Col md={12}>
              <img
                src={crown}
                style={{ width: "40px", margin: "10px 10px 10px 0" }}
                alt="crown-img"
              />
              <h3>{t("wealth:mbTitle")}</h3>
              <p style= {{whiteSpace: "pre-line"}}>
                {t("wealth:mbDescription")}
              </p>
            </Col>
          </Row>
        </Container>
        <div
          style={{
            position: "absolute",
            left: "0",
            bottom: "30%",
            zIndex: "1",
          }}
        >
          <img src={greyPoint} style={{ width: "100vw" }} alt="grey-point" />
        </div>
      </div>
      <div
        id="fh5co-course-title"
        className="grey-background"
        style={{ position: "relative" }}
      >
        <Container
          style={{ padding: "40px 0 0", position: "relative", zIndex: "100" }}
        >
          <Row>
            <Col sm={12} className="animate-box dimback" style={{
              backgroundImage: `url(${wealth})`,
              backgroundPositionX: 'center',
              backgroundSize: 'cover',
              aspectRatio: width > 768 ? 2.5 : 1.5,
              backgroundPositionY: '60%',
            }}>
              <span
                style={{
                  position: "absolute",
                  top: "28%",
                  left: "5%",
                  fontSize: "30px",
                }}
              >
                &#11088;
              </span>
              <span
                style={{
                  position: "absolute",
                  top: "30%",
                  left: "10%",
                  color: "white",
                  fontSize: "25px",
                }}
              >
                {t("wealth:jcTitle")}
              </span>
              <span
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "5%",
                  color: "white",
                  whiteSpace: "pre-line"
                }}
              >
                {t("wealth:jcDescription")}
              </span>
              <span style={{ position: "absolute", top: "40%", left: "80%" }}>
                <img
                  src={wealth_qr}
                  style={{ width: "65%" }}
                  alt="wealth_qr-img"
                />
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default WealthClub;
