export default {
  more: "更多",
  fService: "基金服务",
  fRegistry: "基金注册",
  fAdministration: "基金管理",
  cServices: "托管业务",
  tServices: "第三方委托",
  sInclude: "业务包括：",
  fIntro: "海利基金是一个独立的基金管理团队，为客户提供一系列全方位的基金服务，包括基金注册，基金管理，基金托管以及第三方委托业务。无论您是经营全球范围基金的大型机构还是处于创业初期的中小型基金经理，我们的团队都具备专业的业务能力，支持我们的合作伙伴实现投资策略及目标。",
  
  f1Intro: "海利基金为澳洲的金融行业从业者提供专业和低费用的基金注册服务，作为一家具备多年经验的基金管理公司，海利基金会确保基金注册的全部流程符合澳洲证券投资委员会的法律以及合规要求，保证基金注册者的安全。",
  f1s1: "单位信托注册",
  f1s2: "股票注册",
  f1s3: "信息管理",
  f1s4: "资金来源审核（参照反洗钱及打击恐怖分子资金筹集条例）",
  f1s5: "基金红利发放",
  f1s6: "Quarterly/Monthly investor reporting",

  f2Intro: "海利基金可以作为独立的第三方，确认以及准确评估基金价值，帮助基金经理可以更专注于他们的核心业务，第三方的基金管理业务同时也为投资者提供了额外的保护。",
  f2s1: "管理资金及会计记录",
  f2s2: "资产明细以及估值",
  f2s3: "净值计算",
  f2s4: "提供财务报表",
  f2s5: "处理付款",
  f2s6: "经营活动报表准备及递交",
  f2s7: "配合外部审计准备文件",
  f2s8: "按照澳洲会计准则准备年度法定报表",

  f3Intro: "海利基金为客户提供基金托管业务来保护基金的安全，基金经理通过委托独立的托管团队，可以使自己的工作更加高效并专注于基金经理的本职工作，由专业的托管团队完成交付，保管和准备报告等材料。",
  f3s1: "合法持有资产",
  f3s2: "持有并记录认证账户的转账",
  f3s3: "提供网上银行和实时全额支付系统设备以及服务",
  f3s4: "提供网银转账，支票，实时全额支付以及电汇付款服务",
  f3s5: "收款并记录托管基金及到期收益",
  f3s6: "银行账户明细帐记录",
  f3s7: "完成相关租赁，出售，购买，债务机构及公司活动文件",
  f3s8: "每季度合规报告，有形资产确认",
  f3s9: "可控性年度审计",

  f4Intro: "海利基金的第三方托管团队曾担任多种架构基金的第三方受托人，有着超过20年的业务经验，为未在澳大利亚证券交易所注册的自管基金提供第三方委托人服务。我们会评估基金的合规性以及管理流程，确保基金的运营符合信托契约，相关披露文件以及法律法规的要求。",
  f4s1: "处理相关文件",
  f4s2: "审核投资决策",
  f4s3: "合规审核",
  f4s4: "托管业务",
  f4s5: "外部业务管理",
  f4s6: "计划管理",
  f4s7: "基金及单位基金管理",
  f4s8: "注册服务",

  fConsult: "金融咨询",
  fsConsult: "金融服务咨询",
  csConsult: "企业战略咨询",
  eConsult: "创业咨询",
  
  fsIntro: "战略审查与制定 \n 新市场的进入策略以及新产品的服务和开发 \n 识别和执行创造价值的交易 \n 优化运营 \n 在诉讼程序和监管事务中主张并保护其利益",
  csIntro: "投资组合策略 \n 资源分配 \n 战略管理与规划",
  eIntro: "确定旨在通过增加销量或发现收购机会的策略来扩大收入的选择 \n 定位其业务以进行长期收购 \n 应对因增长或需要周转而日益增加的业务复杂性",

  wcPlatform: "海利财富",
  aAllocation: "海利财商课程",
  bwClub: "财富端专属产品",

  aaIntro: "",
  bwcIntro: "",
}
