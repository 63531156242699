export default {
    mBenefit: "会员福利",
    mIntro: "投资项目最优先知情权和投资机会 \n 免费参加海利每月一次的下午茶活动 \n 免费参加海利每年年会晚宴庆祝活动 \n 免费参加海利不定期固定项目募资完成和项目退出庆祝晚宴活动 \n 以悉尼为主的各知名餐厅优惠折扣 \n 定期富豪名人见面聚会 \n 墨尔本100万平方米，风景秀丽独岛优惠旅游三天，专机接送 \n 每月定制活动：钓鱼、卡拉OK、高尔夫、游艇、洲际游以及出国游、徒步等",

    mAccount: "我的账户",
    bid: "海利 ID: ",
    name: "姓名: ",
    email: "邮箱: ",
    phone: "电话: ",
    birth: "生日: ",
    password: "密码: ******",
    p: "密码",
    pConfirm: "确认",
    change: "更改信息",
    iProjects: '投资项目及金额: ',
    fProjects: "融资项目及金额: ",
    fName: "基金名称",
    tAmount: "总金额",
    aIncome: "累积收益",
    aCommission: "累积佣金",
    total: "总计",
    signout: '退出登录',

    mProfile: "财富合伙人佣金总览: ",
    eCommission: "预计佣金: ",
    cName: '客户名称',
    sTime: '开始时间',
    tIncome: '客户截至今日总收益',
    cRate: '佣金率',
    cAmount: '截至今日佣金金额',
    mDetail: "本月详情",
    mCommission: "本月累积佣金:",
    nTime: "下次派息时间: ",

    fDescription: "基金简介: ",
    fType: "基金类型",
    iType: "投资类型",
    pType: "产品类型",
    minAmount: "最低投资额",
    iCycle: "投资周期",
    fixReturn: "固定净收益",
    floatReturn: "浮动净收益",
    aFee: "申请费率",
    mFee: "管理费率",
    nReturnA: "类别A目标净回报",
    nReturnB: "类别B目标净回报",
    cCycle: "分红周期",
    pFee: "绩效费",
    iStrategy: "投资策略",
    nyReturn: "自然选择基金预计年均收益",
    vyReturn: "价值投资基金预计年均收益",
    sFee: "认购费",
    iDetail: "投资详情",
    iAmount: "投资金额: ",
    iTime: "投资时间: ",
    eTime: '到期时间: ',
    eReturn: '预计总收益: ',
    cTReturn: "截至今日总收益: ",
    mTBenefit: "本月累积收益: ",
    hRecord: "历史派息记录",
    fInfo: "基金资料 IM (PDF)",
    fIntro: "基金简介 (PDF)",
    reInvest: "复投表 (PDF)",
    fReport: "基金报告 (PDF)",
    eoi: "EOI 投资意向表 (PDF)",
    warning: "注意",
    noRecord: "您的投资未满一个月，暂未生成历史记录",

    time: "时间",
    location: "地点",
    aDescription: "活动简介",
    end: "已结束",
    register: "报名",
    rParticipate: "报名参加",
    rParticipant: "参加人数",

    rnVerification: "实名认证",
    dTime: "派息时间",
    dAmount: "派息金额",

    submitVerify: "提交审核",
    document: "文件",

    online: "线上",
    sydney: "悉尼",
    melbourne: "墨尔本",
    brisbane: "布里斯班",
    perth: "珀斯",
    adelaide: "阿德莱德",
    canberra: "堪培拉",
    hobart: "霍巴特",
    goldcoast: "黄金海岸",
    otherCities: "其他城市",

    nextStep: "下一步",
    coplete: "完成",
    noInvestorType: "请选择您的投资主体",
    sdkFail: "SDK 初始化失败，请刷新页面重试",
    verifySuccess: "实名认证已提交",
    verifyFail: "实名认证失败",

    country: "国家",
    documentType: "证件类型",
    livePhoto: "实况照片",
    uploadDocuments: "上传证件",
    verifyFinish: "实名认证完成",
    finishNotice: "证件已经上传，请等待管理员审核，感谢使用海利基金",

	verifyWaiting: '验证中',
	verifyApproved: '已验证',
	verifyNotMatch: '验证不匹配',

    ComingSoon:"即将开放",
    NowOpen:"开放中…",
    FullyInvested:"项目已满",
    Completed:"成功项目",

    mTips: "目前系统升级中，会有利息计算误差，请以您收到的利息对账单上的数额为准。",
    
}