/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	yes: "确认",
	cancel: "取消",
	back: "返回",
	search: "搜索",
	login: "登录",
	register: "注册",
	language: 'English',
	cardTitle: "随时了解海利的动向",
	cardsubTitle: "注册邮箱可收到我们对全球经济和市场的最新研究",
	name: "姓名",
	email: "邮箱",
	phone: "电话",
	submit: "确认",
	userName: "用户名",
	password: "密码",
	forgot: "忘记密码？",
	loadMore: "更多",
	myAccount: "我的账户",
	fillAllFields: "请填写所有字段",
	subSuccess: "订阅成功",

	resetPw: "重置密码",
	verifyCode: "验证码",
	sendCode: "发送验证码",
	newPw: "新密码",
	confirmPw: "确认密码",

	selectInterest: "请选择项目",
	state: "州",
	suburb: "区",
	loanOrigination: "贷款发放",
	fundsServices: "基金服务",
	getLatest: "获得最新资讯",
	discoverVietnam: "探索越南",
	vietnam: '财富俱乐部-探索美丽的越南',
	more: '更多',
	Babl: '海利贷款BABL',
};
