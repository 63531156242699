export default {
    fundManagement: "海利基金",
    fundService: "海利服务",
    financialConsultation: "海利咨询",
    wealthCreationPlatform: "海利财富",

    p1Title: "海利一级抵押债优选基金",
    p1subTitle: "海利优选一级抵押债权基金是目前对投资者开放的债权型基金，该基金投资于以澳大利亚商业或住宅为抵押物的一级抵押贷款，在借款人具备良好信用记录的前提下，严格控制贷款额与物业估值的贷款估值比率低于65%，目标净收益达到年化固定8%，为客户提供每月分红收入，资金保管以及完善资产配置的服务。",
    pt1: "基金结构: ",
    pt2: "投资者:  ",
    pt3: "最低投资额: ",
    pt4: "最低投资期: ",
    pt5: "目标回报(费后净收益): ",
    pt6: "派息: ",
    pt7: "单价: ",
    pt8: "Floating return: ",
    pt9: "申请费: ",
    pt10: "管理费: ",

    p1d1: "私募开放式管理基金(单位信托)",
    p1d2: "仅面向成熟事业型投资者",
    p1d3: "$50,000(澳元)",
    p1d4: "9个月",
    p1d5: "固定8%每年",
    p1d6: "每月",
    p1d7: "$1.00/单位",

    p2Title: "阿德莱德CBD地标投资基金",
    p2subTitle: "阿德莱德CBD地标投资基金是目前对投资者开放的地产基金，通过投资优质地产开发项目为基金投资者带来收益，该基金为投资者提供参与阿德莱德中央市场重建项目的机会，投资以贷款的形式参与到项目中，帮助项目建成，基金投资者的收益由每季度的固定收益，以及到期时基金分配的浮动收益组成。\n 1. 阿德莱德住宅市场呈现长期稳定增长形势 \n 2. 政企合作项目及分阶段发展策略 \n 3. 优先保护本金机制 \n 4. 商业房地产市场正在振兴 \n 5. 商业房地产市场正在振兴 \n 6. 强大的风险管理和治理",

    p2d1: "私募封闭式投资专项型基金（单位信托）",
    p2d2: "仅面向成熟投资者",
    p2d3: "$500,000(澳元)",
    p2d4: "48-60个月",
    p2d5: "10-15% 每年 (其中，固定收益每年5%，于每季度分配)",
    p2d6: "固定利息每季度分配，浮动利息在基金赎回时分配",
    p2d7: "Calculated and distributed at redemption upon maturity",
    p2d8: "$1.00/单位",
    p2d9: "1.50%",
    p2d10: "2.00%",

    p3Title: "游艇基金",
    p3subTitle: "悉尼游艇俱乐部投资基金将通过投资经理以及运营公司多年以来的行业经验，按照设定的比例，通过购买不同价格区间高性价比的游艇，以及多年积累的客户资源，成熟的商业模式，将游艇转化为可带来高收益的资产，并结合专业机械团队的日常养护，最大程度上延长游艇使用寿命，实现游艇的使用价值，为投资者带来收益。\n 投资者通过持有该基金的信托单位持有游艇俱乐部相关的游艇，设备及其他资产，定期通过分红的形式，将通过游艇及其他设备的租金，以及游艇俱乐部通过商业活动所产生的分红分配给投资者。目前该基金处于EOI阶段，不为投资者开放申请通道。",

    p3d1: "私募封闭式投资专项型基金（单位信托）",
    p3d2: "仅面向成熟投资者",
    p3d3: "$50,000(澳元)",
    p3d4: "2年",
    p3d5: "20-25% 每年 (其中，固定收益每年6%，于每月分配; 浮动收益及留存收益每年分配)",
    p3d6: "每季度",
    p3d7: "$1.00/Unit",
    p3d8: "1.00%",
    p3d9: "2.00%",
    p3d10: "扣除固定收益及留存收益之后部分的50%",

    p4Title: "Mott 32 投资基金",
    p4subTitle: "Mott 32投资基金投资于建立悉尼首家Mott 32餐厅，Mott 32是世界知名餐厅品牌，在全球共有4个以及餐厅，分别位于香港，拉斯维加斯，新加坡和温哥华，是亚洲获奖最多的餐厅品牌。投资人将通过持有基金份额，间接持有Mott 32餐厅的所有产权，以及50%的股权。通过基金投资经理以及Mott32管理团队多年以来的行业经验，选取并购买优质商业物业，完成装修，并结合成功的酒店运营模式，持续的运营Mott 32餐厅，将通过房产租赁产生的租金，以及Mott 32餐厅运营所产生的收益分配给投资人。目前该基金处于EOI阶段，不为投资者开放申请通道。",
    p4d1: "Arthur Street Capital Management",
    p4d2: "Arthur Street Capital Management Pty Ltd is a private company incorporated in Australia on 17 December 2018. It was established by a group of leading investment professionals, and offers venture capital fund to investors. The fund’s objectives are to seek to invest in firms that have high-risk/high-return profiles, based on a company‘s size, assets and stage of product development and also to create and add exceptional value to investors’ wealth. With extensive collective experience and expertise gained in both the corporate and financial worlds, Arthur Street Capital Management is able to offer its clients thought leadership and original investment ideas that set Arthur Street Capital Management apart from average investment house.",
    p4d3: "BCU",
    p4d4: "The BCU incubator establishes an industrial park and sets up a special fund in Australia. By providing high-quality start-up teams with site support, technical support, industry resource guidance, and financial support. We integrate the industrial resources of China and Australia to create a better entrepreneurial environment from a global perspective, and aim to help Australian startups expand into China and other overseas markets.",

    ourService: "业务",
    serviceIntro: "海利基金是一支专业的基金管理团队，为广大合作伙伴提供全方位金融服务。包括基金产品，基金业务，金融咨询服务和财富端平台服务。无论您是基金经理还是投资人，我们的专业团队都会从您的角度出发，为您提供高水平的解决方案和全方位服务。",
    bcvc: "海利一级抵押债优选基金" ,
    bcdiof: "阿德莱德CBD地标投资基金",
    bclof: "游艇基金",
    bcif: "Mott 32 投资基金",
    fundRegistry: "基金注册",
    fundAdministration: "基金管理",
    custodyServices: "托管服务",
    trusteeService: "第三方委托",
    fsConsulting: "金融服务咨询",
    csConsulting: "企业战略咨询",
    eConsulting: "创业咨询",
    assetAllocation: "资产配置",
    bcwealthClub: "海利财富俱乐部",
};
