import { SET_FRAME } from "../actions";
const initalState = {
  frame: {
    showHeader: true,
    showFooter: true,
  },
};
const FrameReducer = (state = initalState, action) => {
  switch (action.type) {
    case SET_FRAME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default FrameReducer;
