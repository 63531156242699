/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	home: '主页',
	about: '关于我们',
	ourStory: '企业历史',
	ourPeople: '海利团队',
	partnerWithUs: '合作',
	workWithUs: '加入我们',
	insightsAndNews: '观点&资讯',
	beaverForum: '海利大讲堂',
	globalWeeklyWrap: '全球热点',
	latestInsightsAndNews: '市场话题分析',
	beaverCapitalNews: '海利动态',
	ourServices: '产品&业务',
	fundsManagement: '海利产品',
	fundServices: '海利基金',
	financialConsultation: '海利咨询',
	wealthCreationPlatform: '海利财富',
	investWithUs: '投资我们',
	whyInvestWithUs: '选择我们',
	howToInvest: '开始投资',
	frequentQuestions: '常见问题',
	wealthClub: '财富俱乐部',
	pInfo: "个人信息",
	pWealth: "财富增值",
	pFunds: "在投基金",
	pOthers:"其他基金产品",
	pActivities:"近期活动",
	loanOrigination: "海利贷款",
	beaverABL: "海利资产贷款（BABL）",
	constructionLoans: "海利建筑贷款",
	structuredFinance: "结构性融资",
	bigDeals: "大交易",
}
