/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	yes: "Confirm",
	cancel: "Cancel",
	back: "Back",
	search: "Search",
	login: "Login",
	register: "Register",
	language: '中文',
	cardTitle: "Stay with Beaver",
	cardsubTitle: "Contact us for more information and be in the draw for an amazing prize!",
	name: "Name",
	email: "Email",
	phone: "Phone",
	submit: "Submit",
	userName: "Username",
	password: "Password",
	forgot: "Forgot Password?",
	loadMore: "Load More",
	myAccount: "My Account",
	fillAllFields: "Please fill all fields",
	subSuccess: "Subscription Success",

	resetPw: "Reset Password",
	verifyCode: "Verification Code",
	sendCode: "Send Code",
	newPw: "New Password",
	confirmPw: "Confirm Password",

	selectInterest: "Select Your Interest",
	state: "State",
	suburb: "Suburb",
	loanOrigination: "Loan Origination",
	fundsServices: "Funds Services",
	getLatest: "Get Latest Information",
	discoverVietnam: "Discover Vietnam",
	vietnam: 'Wealth Club - Discover the Beauty of Vietnam',
	more: 'More',
	Babl: 'Beaver Asset Based Lending',
};
