import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { createLogger } from "redux-logger";
import promiseMiddleware from "redux-promise-middleware";
import rootReducer from "./redux/reducers";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";

const persistConfig = {
  key: "state",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk, promiseMiddleware, reduxImmutableStateInvariant()];
if (process.env.NODE_ENV !== "production") {
  middlewares.push(createLogger());
}

const configureStore = (preloadedState) => {
  const store = createStore(
    persistedReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
