export default {
    enquire: "咨询",
    loanOrigination: "海利贷款",

    babl: "海利资产贷款 (BABL)",
    bablIntro: "海利资产贷款 (BABL) 使企业能够腾出资产用于营运资金、资本支出或其他资金需求。借助BABL，您公司的各种资产可以用作抵押品，从而释放所需的资金。",

    bcl: "海利建筑贷款",
    bclIntro: "海利在贷款方面拥有丰富的经验和专业知识。 海利一直积极为大量商业、工业、零售和住宅项目提供贷款。海利建筑贷款有着取决于贷方的可变利率。",
    bclSubtitle: "可用的产品范围包括：",
    bclSubText1: "土地银行融资，利息按月资本化或支付",
    bclSubText2: "土地收购与重新分区/规划批准提升的估值相比",
    bclSubText3: "逐步为开发商建筑商或开发商外包建设提取建设资金，涵盖预算建设、建设应急、项目管理、顾问成本和持有成本、建设期间资本化的利息和预算出售期间",
    bclSubText4: "结构性融资解决方案可将项目成本的负债率提高至高达 95%，以提高股本回报率、向开发商释放股本、获得更高价值的项目或提供跨多种资产的过渡性融资",
    bclSubText5: "开发剩余库存的取出/资本重组，以清除建筑贷款、释放股权或提供延长的销售期",

    bsf: "海利结构性融资",
    bsfText1: "结构化金融可以比作一群才华横溢的单一技术专家管弦乐队的指挥。 海利的理想定位是通过单一的条款清单和设施经理来协调每个贷方，并为需要注资或其他融资来源的主要借款人提供便利当其他更传统的借款选择不起作用。 海利提供了一种节省时间和法律费用的方法。",
    bsfText2: "海利确保借款人的利益受到保护，交易不会因协调不善或成本加倍而失败。 结构性融资涉及金融借贷工具，旨在减轻与复杂资产相关的严重风险。海利过往交易项目。",

    bbd: "海利大交易",

    title1: "Annandale NSW",
    des1: "悉尼 CBD 招待所",
    val1: "价值 $ 7,500,000",
    time1: "四月 2022",

    title2: "Paradise Point QLD",
    des2: "带码头的黄金海岸海滨豪宅",
    val2: "价值 $ 3,900,000",
    time2: "三月 2022",

    title3: "Redfern NSW",
    des3: "CBD 零售和住宅 - 混合用途",
    val3: "价值 $ 5,300,000",
    time3: "四月 2022",

    title4: "Cringila NSW",
    des4: "度假村酒店酒吧和博彩",
    val4: "价值 $ 28,000,000",
    time4: "三月 2022",

    title5: "Alexandria NSW",
    des5: "住宅 - 未来发展潜力",
    val5: "价值 $ 7,680,000",
    time5: "四月 2022",

    title6: "Adelaide SA",
    des6: "CBD位置的工业地产",
    val6: "价值 $ 2,000,000",
    time6: "三月 2022",

    title7: "Alexandria NSW",
    des7: "零售和商业厨房",
    val7: "价值 $ 5,000,000",
    time7: "四月 2022",

    title8: "Coorabell NSW",
    des8: "主要农村发展潜力",
    val8: "价值 $ 20,000,000",
    time8: "三月 2022",

    title9: "Mount Hunter NSW",
    des9: "国际机场位置",
    val9: "价值 $ 29,500,000",
    time9: "四月 2022",

    title10: "Parwan WA",
    des10: "石油/天然气服务站和咖啡馆站点",
    val10: "价值 $ 28,000,000",
    time10: "December 2021",

    title11: "Rockingham Beach WA",
    des11: "",
    val11: "价值 $ 2,630,000",
    time11: "January 2022",

    title12: "Annanndale NSW",
    des12: "悉尼酒店",
    val12: "价值 $ 34,350,000",
    time12: "November 2021",
}