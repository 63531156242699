export default {
  more: "More",
  fService: "Fund Services",
  fRegistry: "Fund Registry",
  fAdministration: "Fund Administration",
  cServices: "Custody Service",
  tServices: "Trustee Service",
  sInclude: "Services Include: ",
  fIntro: "Beaver Capital is an independent fund manager providing clients with complete outsourcing solutions and a comprehensive suite of fund services, includes registry, administration and trustee services. Whether you are a boutique fund manager or an established global player, our team has the practical expertise to provide a service that supports your strategic objectives.",
  
  f1Intro: "Beaver One, a Beaver Capital subsidiary, is a premium provider of professional and cost effective registry services for financial market participants in Australia. As an established operator with technical expertise in administering funds, Beaver One is committed to ensuring registers are securely maintained in accordance with all legislative and regulatory requirements.",
  f1s1: "Unit trust registry",
  f1s2: "Share registry",
  f1s3: "Enquiry management",
  f1s4: "Anti-money laundering and Counter Terrorism Financing",
  f1s5: "Dividends and distributions",
  f1s6: "Quarterly/Monthly investor reporting",

  f2Intro: "Beaver Capital can independently verify and accurately value fund assets as a third party, enable fund managers to focus on their core aspects of services while providing investors with added layer of protection.",
  f2s1: "Managing financial and accounting records",
  f2s2: "Portfolio reconciliation and valuation",
  f2s3: "NAV calculation",
  f2s4: "Providing reporting packs of financial statements",
  f2s5: "Arranging payments of operational expenses",
  f2s6: "Preparing and lodging BAS returns",
  f2s7: "Preparing books and records to facilitate external audit",
  f2s8: "Preparing annual statutory accounts in accordance with Australian Accounting Standards",

  f3Intro: "Beaver Capital provides our clients with custody services to protect the assets of a managed investment against the risk of theft or loss, it is an effective way to appoint an independent custodian to systematically settlement, safekeeping and reporting a managed fund while the fund manager can focus on the fiduciary and investment of the fund. We offer a full suite custody services to assist wholesale and retail investment products.",
  f3s1: "Legal ownership of scheme assets",
  f3s2: "Holding and recording cash and money market transactions in identifiable accounts",
  f3s3: "Provision of on-line banking and Real Time Gross Settlement (RTGS) facilities",
  f3s4: "Making payments based on clients’ instructions including electronic funds transfers, bank cheques, RTGS and telegraphic transfers",
  f3s5: "Receipt and recording of all custody and income maturities",
  f3s6: "Holding and recording scheme title documents in secure, fire rated and scheme segregated facilities",
  f3s7: "Execution of documents in accordance with client instructions relating to the scheme assets including leases, sale and purchase agreements, debt facilities and documents for corporate actions",
  f3s8: "Quarterly compliance reports, confirmations of net tangible assets and asset confirmations",
  f3s9: "Annual audit of our control environment",

  f4Intro: "Beaver one, a Beaver Capital subsidiary, has built a reputation in Australia from more than 20 years’ experience of trustee services across a variety of trust structures and assets. As a trustee to unregistered managed investment schemes, Beaver Capital is responsible for assessing the compliance and governance of the trust and ensure the trust operations conform to its trust deed, disclosure documents and the law.",
  f4s1: "Producing offer documents",
  f4s2: "Overseeing investment decisions",
  f4s3: "Adhering to legislative and regulatory requirements",
  f4s4: "Custody services",
  f4s5: "Management of eternal service providers",
  f4s6: "Scheme administration",
  f4s7: "Fund and unit accounting",
  f4s8: "Registry services",

  fConsult: "Financial Consultation",
  fsConsult: "Financial Services Consulting",
  csConsult: "Corporate Strategy Consulting",
  eConsult: "Entrepreneurial Consulting",
  
  fsIntro: "Strategy review and development \n New market entry strategy, and new product and service development \n Identification and execution of value-creating transactions \n Optimization of operations \n Advocate and protect their interests in litigation proceedings and regulatory matters",
  csIntro: "Portfolio strategy \n Resource allocation \n Strategic management and planning",
  eIntro: "Identify options to expand their revenues through strategies designed to increase sales or identify acquisition opportunities \n Positioning their businesses for longer-term acquisition \n Deal with increasing business complexity due to growth or the need for a turnaround",

  wcPlatform: "Wealth Creation Platform",
  aAllocation: "Asset Allocation",
  bwClub: "BC Wealth Club",

  aaIntro: "Just as the old adage “Don’t put all your eggs in one basket” goes, a long-term diversified asset mix is key to investing wisely. Enjoy the privilege of accessing a comprehensive suite of investment products and wealth solutions to create a portfolio tailored to your needs, in line with your risk profile. Our portfolio will be mixed with fixed income, hedge fund, equity and venture capital according to clients’ risk adverse level and financial situation.",
  bwcIntro: "BC wealth club is an exchange and cooperation platform gathering tens of millions of wealthy class, relying on professional superior resources, serving high-end income groups with social influence and asset allocation needs, aiming to create private business exchanges and asset optimization high-end wealth circle. BC wealth club build an international elite business exchange circle, and hold hundreds of high-end salons, expert lectures, summit forums and other activities for members. The core involves investment, financial management, economics, finance, insurance, education, taxation, law, medical care, health care, etc. Field; provide one-to-one exclusive consulting services for high-end wealth management and household asset allocation; provide an exclusive platform for member customized activities to realize business information exchange and cooperation. After years of development, the club has a good reputation and strong resources in the industry. It has established long-term cooperation with many top international wealth management, financial institutions, well-known family offices, top tier real estate, developers and banks, and has cooperated with domestic and foreign politicians, economists, investment experts, wealth management experts, and well-known Scholars, celebrities, etc. have a good relationship.",
}
